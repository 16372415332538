var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-lottery"},[_c('div',{staticClass:"border-box"},[_c('div',{staticClass:"border-box-body"},[_c('div',{staticClass:"border-box-body-hd"},[_c('div',{staticClass:"van-row van-row--flex van-row--justify-space-between"},[_c('div',{staticClass:"van-col van-col--12"},[_c('div',{staticClass:"text-left"},[_c('span',[_vm._v(" "+_vm._s(_vm.time.lotto_name)+" "+_vm._s(_vm.time.year_name)+_vm._s(_vm.time.journal_name)+"期")]),_vm._v("开奖 ")])]),_c('div',{staticClass:"van-col van-col--12"},[_c('div',{staticClass:"text-right"},[_c('span',{on:{"click":_vm.onClickRight}},[_vm._v("历史记录")])])])])]),_c('div',{staticClass:"border-box-body-bd"},[_c('div',{staticClass:"van-row"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"van-col van-col--3"},[(index == 6)?_c('div',{staticClass:"connect"},[_c('i',{staticClass:"van-icon van-icon-plus",staticStyle:{"color":"rgb(172, 172, 172)"}})]):_c('div',{staticClass:"icon-text"},[_c('div',[_c('span',{class:item.bose_name == '绿波'
                      ? 'slot-icon bg-amage-3'
                      : item.bose_name == '红波'
                      ? 'slot-icon bg-amage-1'
                      : item.bose_name == '蓝波'
                      ? 'slot-icon bg-amage-2'
                      : 'slot-icon bg-amage-3',style:(item.haoma_name == 0
                      ? 'background-image: url(\'/img/no_jiang.png\')'
                      : '')},[_c('span',[_vm._v(_vm._s(item.haoma_name == 0 && index == 0 ? "封" : item.haoma_name == 0 && index == 1 ? "盘" : item.haoma_name == 0 && index == 2 ? "中" : item.haoma_name == 0 && index == 3 ? "等" : item.haoma_name == 0 && index == 4 ? "待" : item.haoma_name == 0 && index == 5 ? "开" : item.haoma_name == 0 && index == 7 ? "奖" : item.haoma_name))])])]),_c('div',{staticClass:"slot-text"},[_vm._v(" "+_vm._s(item.animal_name)),_c('span',[_vm._v(_vm._s(item.haoma_name > 0 ? "/" : "")+_vm._s(item.five_name))])])])])}),0)]),_c('div',{staticClass:"border-box-body-ft"},[_vm._v(" 第"+_vm._s(_vm.preparation.journal)+"期 "+_vm._s(_vm.preparation.time)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }