<template>
  <div class="block-lottery">
    <div class="border-box">
      <div class="border-box-body">
        <div class="border-box-body-hd">
          <div class="van-row van-row--flex van-row--justify-space-between">
            <div class="van-col van-col--12">
              <div class="text-left">
                <span>
                  {{ time.lotto_name }} {{ time.year_name
                  }}{{ time.journal_name }}期</span
                >开奖
              </div>
            </div>
            <div class="van-col van-col--12">
              <div class="text-right">
                <span @click="onClickRight">历史记录</span>
              </div>
            </div>
            <!---->
          </div>
        </div>
        <div class="border-box-body-bd">
          <!-- v-if="iskaijiang == 0 || iskaijiang == 2" -->
          <div class="van-row">
            <div
              class="van-col van-col--3"
              v-for="(item, index) in list"
              :key="item.id"
            >
              <div class="connect" v-if="index == 6">
                <i
                  class="van-icon van-icon-plus"
                  style="color: rgb(172, 172, 172)"
                ></i>
              </div>
              <div class="icon-text" v-else>
                <div>
                  <span
                    :class="
                      item.bose_name == '绿波'
                        ? 'slot-icon bg-amage-3'
                        : item.bose_name == '红波'
                        ? 'slot-icon bg-amage-1'
                        : item.bose_name == '蓝波'
                        ? 'slot-icon bg-amage-2'
                        : 'slot-icon bg-amage-3'
                    "
                    :style="
                      item.haoma_name == 0
                        ? 'background-image: url(\'/img/no_jiang.png\')'
                        : ''
                    "
                    ><span>{{
                      item.haoma_name == 0 && index == 0
                        ? "封"
                        : item.haoma_name == 0 && index == 1
                        ? "盘"
                        : item.haoma_name == 0 && index == 2
                        ? "中"
                        : item.haoma_name == 0 && index == 3
                        ? "等"
                        : item.haoma_name == 0 && index == 4
                        ? "待"
                        : item.haoma_name == 0 && index == 5
                        ? "开"
                        : item.haoma_name == 0 && index == 7
                        ? "奖"
                        : item.haoma_name
                    }}</span></span
                  >
                </div>

                <div class="slot-text">
                  {{ item.animal_name
                  }}<span
                    >{{ item.haoma_name > 0 ? "/" : ""
                    }}{{ item.five_name }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="van-row" v-if="iskaijiang == 1 || !list.length">
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 封 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 盘 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 中 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 等 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 待 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 开 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="connect">
                <i
                  class="van-icon van-icon-plus"
                  style="color: rgb(172, 172, 172)"
                ></i>
              </div>
            </div>
            <div class="van-col van-col--3">
              <div class="icon-text">
                <div>
                  <span
                    class="slot-icon bg-amage-3"
                    style="background-image: url('/img/no_jiang.png')"
                    ><span> 奖 </span></span
                  >
                </div>
                <div class="slot-text"><span></span></div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="border-box-body-ft">
          第{{ preparation.journal }}期 {{ preparation.time }}
        </div>
      </div>
    </div>
  </div>
  <!--
      <div class="border-box" v-if="time.year_name>0">
                  <div class="border-box-body">

                      <div class="body-hd-2">{{ time.lotto_name }} {{ time.year_name }}年 第{{ journal_id }}期</div>
                      <div class="border-box-body-bd">
                          <div class="van-row">

                              <div class="van-col van-col--3" v-for="(item,index) in list" :key="item.id">

                                  <div class="connect" v-if="index==6"><i class="van-icon van-icon-plus" style="color: rgb(172, 172, 172);"></i></div>

                                  <div class="icon-text" v-else>

                                      <div><span :class="item.bose_name=='绿波' ? 'slot-icon bg-amage-3' : item.bose_name=='红波' ? 'slot-icon bg-amage-1' : item.bose_name=='蓝波' ? 'slot-icon bg-amage-2':'slot-icon'"><span>{{ item.haoma_name }}</span></span></div>
                                      <div class="slot-text">
                                          {{ item.animal_name }}<span>/{{ item.five_name }}</span></div>

                                  </div>
                              </div>

                          </div>
                      </div>

                  </div>
              </div>
  -->
</template>

<script>
export default {
  props: {
    lotto_id: Number,
  },
  watch: {
    //监听props 值变化
    year_id() {
      this.getData();
    },
    lotto_id() {
      this.getData();
    },
    journal_id() {
      this.getData();
    },
  },
  data() {
    return {
      active: "picture",
      time: [{ year_name: 0 }],
      iskaijiang: 0,
      list: [],
      data: [],
      preparation: [],
      lotto_list: [],
      journal_list: [],
      year_list: [],
      loading: false,
      finished: false,
      iserror: false,
      page: 1,
      digit: 0,
      message: "",
      iscolour: 0,
      option1: [],
    };
  },
  created() {
    //5秒钟刷新一次数据
    setInterval(() => {
      this.runTime();
    }, 3000);
  },
  mounted() {
    this.iskaijiang = 1;
    this.getData();
  },
  methods: {
    runTime() {
      if (this.preparation.time) {
        var date_end = new Date(this.preparation.time);
        var end_time = date_end.getTime();
        var now = new Date();
        var now_time = now.getTime();
        // console.log(now_time+'>'+start +' && '+now_time+'<'+end);
        //1694956395506>1694956080000 && 1694956395506<1694956440000

        //时间要* 1000，毫秒单位
        if (now_time > end_time - 60 * 10 * 1000 && this.iskaijiang < 2) {
          this.iskaijiang = 1;
        }

        //console.log('time: '+this.preparation.time+' '+now_time+'>'+end_time +' && '+now_time+'<'+(end_time+60*5*1000));

        if (now_time - 2000 > end_time && now_time < end_time + 60 * 5 * 1000) {
          // *5
          this.getData();
        }
      }
    },
    onClickLeft() {
      // 返回上一级页面
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({ path: "/kaijiang" });
    },
    getData() {
      this.iskaijiang = 1;
      console.log("this.lotto_id", this.lotto_id);
      this.$http
        .get(
          "api/kaiJiang/getnew?lotto_id=" +
            this.lotto_id +
            "&digit=" +
            this.digit +
            "&t=" +
            new Date().getTime()
        )
        .then(function (response) {
          if (response.data.code == "ok") {
            this.list = response.data.data.list || [];
            if (this.list.length > 6) {
              this.list.splice(6, 0, {});
            }
            this.time = response.data.data.time;
            this.iskaijiang = response.data.data.is_kaijiang || 1;
            this.preparation = response.data.data.preparation;
            this.loading = false;
          } else {
            this.list = response.data.data.list || [];
            this.time = response.data.data.time;
            this.preparation = response.data.data.preparation;
            this.iskaijiang = response.data.data.is_kaijiang || 1;
            this.iserror = true;
            this.message = response.data.msg;
          }

          this.loading = false;
          //console.log(this.list);
        });
    },
  },
};
</script>

<style scoped>
.sf {
  display: none;
}
</style>
