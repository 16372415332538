<template>
  <div class="home corpus-wrap">
    <!-- 
      right-text="开奖记录" -->
    <!-- 
      @click-right="onClickRight" -->
    <van-nav-bar
      class="van-nav-bar--fixed"
      title="开奖现场"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>

    <div class="block-lottery">
      <ul class="platform-list" style="padding-top: 10px">
        <li v-for="item in lotto_list" :key="item.id">
          <div
            :class="item.id == lotto_id ? 'item-body act' : 'item-body'"
            @click="setLotto(item.id)"
          >
            <div class="title">
              <b>{{ item.name }}</b>
            </div>
            <div class="time">
              <span>{{ item.time ? item.time : "0月0日" }}开奖</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="border-bg"></div>
      <div
        class="border-box"
        v-for="item in lotto_list"
        :key="item.id"
        style="padding: 0 0"
      >
        <KaiJiangShow
          :key="item.time"
          :lotto_id="item.id"
          :style="item.id == lotto_id ? 'display: block;' : 'display: none;'"
        />
      </div>
    </div>

    <div class="body-image-bd" style="margin-top: 20px">
      <div class="waterfall">
        <div
          v-for="item in list"
          :key="item.id"
          class="image-item"
          @click="play(item.address)"
        >
          <div class="image-item-image">
            <van-image
              style="width: 100%"
              :src="
                item.img.length > 0 ? siteurl + item.img : '/img/no_image.png'
              "
            />
          </div>
          <div class="image-item-title">
            {{ item.videoTitle }}
          </div>
        </div>
      </div>
    </div>

    <div class="body-image-bd" style="margin-top: 20px; text-align: center">
      提示：仅显示近十期
    </div>

    <van-popup
      v-model="show"
      :style="{ padding: '15px', backgroundColor: 'transparent' }"
    >
      <video width="320" height="240" :src="siteurl + video_url" controls>
        您的浏览器不支持Video标签。
      </video>
    </van-popup>
  </div>
</template>

<script>
import KaiJiangShow from "@/components/KaiJiangShow.vue";
// import { Toast } from "vant";
// import { Dialog } from "vant";

export default {
  components: {
    KaiJiangShow,
  },
  data() {
    return {
      lotto_id: 0,
      video_url: "",
      list: [],
      lotto_list: [],
      show: false,
    };
  },
  mounted() {
    //this.active='picture';
    this.lotto_id = parseInt(this.$route.query.lotto_id)
      ? parseInt(this.$route.query.lotto_id)
      : 2;
    this.getIndexData();
    this.getData();
  },
  methods: {
    onClickLeft() {
      // 返回上一级页面
      this.$router.back();
    },
    onClickRight() {
      this.$router.push({ path: "/kaijiang" });
    },
    play(address) {
      this.video_url = address;
      this.show = true;
    },

    setLotto(id) {
      this.page = 1;
      this.list = [];
      this.lotto_id = id;
      this.getData();
    },
    getIndexData() {
      this.$http.get("api/index/index").then(function (response) {
        if (response.data.code == "ok") {
          this.lotto_list = response.data.data.lotto_list;
        } else {
          this.iserror = true;
          this.message = response.data.msg;
        }
        this.loading = false;
        //console.log(this.list);
      });
    },
    getData() {
      this.$http
        .get("api/picture/kjvideo?page=1&cid=" + this.lotto_id)
        .then(function (response) {
          if (response.data.code == "ok") {
            console.log(112, response.data.data.list.data);
            this.list = response.data.data.list.data;
            this.loading = false;
          } else {
            this.iserror = true;
            this.message = response.data.msg;
          }
          this.loading = false;
          //console.log(this.list);
        });
    },
  },
};
</script>
<style>
.block-lottery {
  margin-top: 0;
  /* padding-top: 3px; */
  /* background-color: #eee; */
}
.block-lottery .platform-list li .item-body .time span {
  float: none;
}

.waterfall {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 8px;
}
.waterfall .image-item {
  margin-bottom: 0.2rem;
  box-shadow: 0 0.02rem 0.08rem 0 hsla(0, 0%, 62.7%, 0.5);
  border-radius: 4px;
  border: 1px solid #ededed;
  position: relative;
  padding: 1px;
  width: calc(50% - 16px);
  margin: 8px;
  text-align: center;
}
.image-item-title {
  padding: 4px;
}
.van-image img {
  height: 150px;
  /* 不拉伸 */
  object-fit: cover;
}
</style>
